<template>
  <div class="cap-layout cap-layout-row cap-wrap">
    <div class="cap-col cap-col-12 pa-0">
      <v-slide-group
        center-active
        class="mx-auto"
        v-if="showCarousel"
        :show-arrows="false"
      >
        <v-slide-item
          v-for="(finOrg, index) in finOrgs"
          :key="index"
          v-slot:default="{ active, toggle }"
        >
          <v-btn
            :title="finOrg.name"
            :class="'ml-2 mb-2 mt-2 slider-item-group-' + index"
            height="100px"
            v-if="finOrg.logo != null"
            @click="
              activeIndex = index;
              toggle();
            "
          >
            <v-img
              :input-value="active"
              :alt="finOrg.name"
              :src="getLogo(finOrg.logo)"
              max-width="150px"
              width="150px"
              max-height="90px"
              contain
            ></v-img
          ></v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "CapCarousel",
  props: {
    isLastStep: Boolean,
  },
  computed: {
    finOrgs() {
      return this.$store.getters.allFinOrgs;
    },
    showCarousel(){
        return !this.isLastStep;
    }
  },
  data: () => ({
    activeIndex: 5,
    first: true,
  }),
  methods: {
    slider: function() {
      var finLength = Object.keys(this.finOrgs).length;
      if (this.activeIndex + 1 == finLength) {
        this.activeIndex = 2;
      } else {
        this.activeIndex++;
      }

      if (
        document.getElementsByClassName("slider-item-group-" + this.activeIndex)
          .length > 0 && document.getElementsByClassName("menuable__content__active").length === 0
      ) {
        var element = document.getElementsByClassName(
          "slider-item-group-" + this.activeIndex
        )[0];

        element.click();
      }

      if (this.showCarousel) {
        setTimeout(this.slider, 1500);
      }
    },
    getLogo: function(logo) {
      return logo != "" ? process.env.VUE_APP_CAPSEARCH_MEDIA + "/" + logo : "";
    },
  },
  mounted() {
    this.slider();
  },
};
</script>
