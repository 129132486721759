<template>
  <div class="cap-container">
    <p v-if="label">{{ label }}</p>
    <v-item-group v-model="selected" :class="errors || errorOnInput ? 'has-errors' : ''">
      <div class="cap-container">
        <div class="cap-row justify-center">
          <div class="cap-col" :class="checkMobile" v-for="(value, key) in choices" :key="key">
            <v-item v-slot:default="{ active, toggle }" :value="key">
              <v-card
                class="d-flex align-center"
                :dark="active"
                height="100"
                @click="
                  toggle();
                  update();
                "
              >
                <div class="cap-container flex-grow-1 text-center">
                  {{ value }}
                </div>
              </v-card>
            </v-item>
          </div>
        </div>
      </div>
    </v-item-group>
  </div>
</template>
<script>
export default {
  name: "CapBoxChoice",
  props: {
    choices: Object,
    step: Number,
    fieldName: String,
    value: String,
    errors: Boolean,
    label: String,
    rules: Array,
    errorOnInput: Boolean
  },
  data: () => ({
    selected: 0,
  }),
  methods: {
    update() {
      this.$emit("update-selected", this.fieldName, this.selected);
    },
    resetForm() {
      this.selected = '0';
      this.$emit("update", this.fieldName, this.selected);
    },
  },
  computed: {
    checkMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 'cap-col-12';
        case "sm":
          return 'cap-col-12';
        default:
          return 'cap-col-4';
      }
    }
  },
  watch: {
    value: function(value){
        this.selected = value;
    }
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>
