<template>
  <div class="cap-container">
    <p v-if="label">{{ label }}</p>

        <v-form ref="addressForm" v-model="formIsValid">
          
          <div class="cap-row" v-for="(codes, index) in allCodes" :key="index">
              <div class="cap-col" :class="checkMobile">
                  <v-text-field
                      v-model="allCodes[index].postal_code"
                      label="Postcode"
                      :error="errors"
                      :rules="[rules.postalcode]"
                      outlined
                  ></v-text-field>
              </div>

              <div class="cap-col" :class="checkMobile">
                  <v-text-field
                      v-model="allCodes[index].house_number"
                      outlined
                      label="Huisnummer"
                      :rules="[rules.housenumber]"
                      :error="errors"
                  ></v-text-field>
              </div>


              <div class="cap-col" :class="checkMobile">
                  <v-text-field
                      v-model="allCodes[index].house_number_addition"
                      outlined
                      label="Toevoeging"
                  ></v-text-field>
              </div>

              <!-- Trash button on desktop resolutions -->
              <div class="cap-col cap-col-2" v-if="(index > 0 && cols < 12) || (cols < 12 && allCodes.length > 1)">
                      <v-btn class="v-btn--primary v-btn-col-2" inline-block @click="removeCode(index)" style="background-color:red !important;">
                      <v-icon dark>mdi-trash-can-outline</v-icon>
                  </v-btn>
              </div>

              <!-- Trash button on mobile resolutions -->
              <div class="cap-col cap-col-12 trash-div" v-if="(index > 0 && cols == 12) || (cols == 12 && allCodes.length > 1)">
                  <v-btn class="v-btn--primary v-btn-col-12" inline-block @click="removeCode(index)" style="background-color:red !important;">
                      <v-icon dark>mdi-trash-can-outline</v-icon>
                  </v-btn>
              </div>

              <div class="mobile-spacer"></div>

          </div>

          <div class="cap-row">
                <!-- Add button on desktop resolutions -->
            <div class="cap-col cap-col-12" v-if="cols < 12">
              <v-btn class="v-btn--primary v-btn-col-2" inline-block @click="addFieldBox()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </div>

            <!-- Add button on mobile resolutions -->
            <div class="cap-col cap-col-12" v-if="cols == 12">
              <v-btn class="v-btn--primary v-btn-col-12" inline-block @click="addFieldBox()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </div>

          </div>
        
        </v-form>

  </div>
</template>
<script>

export default {
  name: "CapAddressInput",
  props: {
    nextStep: Function,
    step: Number,
    fieldName: String,
    label: String,
    errors: Boolean,
  },
  data: () => ({
    allCodes: [],
    formIsValid: true,
    postalCodePattern: /(^$|^[0-9]{4} ?[a-zA-Z]{2}$)/,
    houseNumberPattern: /(^$|^[1-9]{1}[0-9]*$)/,
  }),
  watch: {
    // Save postal code(s) to the local store whenever the array changes but only if all values are entered & validated
    // We need to do this manually because the LG is not made to be dealing with dynamically changing forms
    'allCodes': {
      handler (newCodes) {
        // This sets validated to false if there is 1 field not entered or invalid, so we can't continue to the next question
        this.validateInput(newCodes)
      },
      deep: true
    },
  },
  mounted: function () {
    // Add the first postcode & huisnummer field when the component is mounted
    this.allCodes.push({"postal_code": '', "house_number": '', "house_number_addition": ''});
  },
  methods: {
    // Add two empty fields to the array, so they appear in the form but only if the existing fields are validated
    addFieldBox() { 
      if (this.$refs.addressForm.validate()) {
        this.allCodes.push({"postal_code": '', "house_number": '', "house_number_addition": ''});
      }
    },
    // Remove a row from the array and the form
    removeCode(index) {
      this.allCodes.splice(index, 1);
      this.validateInput(this.allCodes);
    },
    // Clear input fields
    clearForm() {
      this.allCodes = [];
      this.allCodes.push({"postal_code": '', "house_number": '', "house_number_addition": ''});
    },
    // Remove everything and reset the form to remove validation messages (for when you click "start opnieuw")
    resetForm() {
      this.clearForm();
      this.resetStoredAnswer();
    },
    // Reset the answer set in the vuex store
    resetStoredAnswer() {
      this.$emit("update-text", this.fieldName, '');
    },
    validateInput(codes) {
      if (this.fieldsCorrect(codes)) { // we need fieldsCorrect for bug #3208
        this.$emit("update-text", this.fieldName, codes);
      } else { // if all fields are entered, but not valid, reset the answer and show the error
        this.resetStoredAnswer();
      }
    },
    // Match regex with input, only return true if all match
    // Fixes bug #3208
    fieldsCorrect(codes) {
      let regexPassed = true;
      for (var i = 0; i < codes.length; i++) {
        const fields = codes[i];
        if (fields.postal_code && fields.house_number) {
          if (!this.postalCodePattern.test(fields.postal_code) || !this.houseNumberPattern.test(fields.house_number)) {
            regexPassed = false;
          }
        } else {
          if (fields.postal_code == "" && fields.house_number !== "" || fields.postal_code !== "" && fields.house_number == "") {
            regexPassed = false;
          }
        }
      }
      return regexPassed;
    },
  },
  computed: {
    // Check if we're on mobile or not
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        default:
          return 10;
      }
    },
    checkMobile() {
        switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'cap-col-12';
        default:
          return 'cap-col-3';
      }
    },
    // Some regex for validation
    rules() {
        return {
          required: value => !!value || this.translate("form.validation.required"),
          postalcode: value => {
            return this.postalCodePattern.test(value) || this.translate("adresvalidatie.postcode");
          },
          housenumber: value => {
            return this.houseNumberPattern.test(value) || this.translate("adresvalidatie.huisnummer");
          },
        }
    },
  }
};
</script>

<style scoped>

.v-btn-col-2 {
  width:20px !important;
  height:54px !important;
}

.cap-col-3, .cap-col-2, .cap-col-12 {
  padding:0 !important;
}

.trash-div {
  padding-bottom:12px !important;
}

.mobile-spacer {
  display:none;
}

/* Phones portrait mode */
@media only screen 
  and (min-device-width: 360px) 
  and (max-device-width: 736px) 
  and (orientation: portrait) { 

    .mobile-spacer {
      display:block;
      width:1px;
      height:20px;
    }

    .v-btn-col-12 {
      display:block;
      width:90%;
      height:45px !important;
    }

}

</style>