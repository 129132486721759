<template>
  <v-card flat>
    <CapResetButton :resetClick="resetClick" />
    <div class="cap-col cap-col-12">
      <CapTranslation
        translation="scoring_page.success_text"
        :params="{ chanceOfSuccess: chanceOfSuccess }"
      />
    </div>
    <div class="cap-row align-center justify-center" :style="{ 'flex-direction': flexDir }">
      <div class="cap-col">
        <div class="cap-row">
          <div class="cap-col">
            <v-card
              class="text-center v-card--primary"
              @click="openQuestion(financingQuestion)"
            >
              <v-card-text><CapTranslation translation="scoring_page.financing_amount"/></v-card-text>
              <span v-html="this.formatWithEuroSign(financingAmount)"></span>
            </v-card>
          </div>
          <div class="cap-col">
            <v-card class="text-center v-card--primary v-card--disabled">
              <v-card-text><CapTranslation translation="scoring_page.max_amount"/></v-card-text>
              <span v-html="this.formatWithEuroSign(maxAmount)"></span>
            </v-card>
          </div>
        </div>
        <div class="cap-row">
          <div class="cap-col">
            <v-card
              class="text-center v-card--primary"
              @click="openQuestion(durationQuestion)"
            >
              <v-card-text
                ><CapTranslation translation="scoring_page.duration"
              /></v-card-text>
              <b>{{ durationDesc }}</b>
            </v-card>
          </div>
          <div class="cap-col">
            <v-card
              class="text-center v-card--primary"
              @click="openQuestion(repaymentQuestion)"
            >
              <v-card-text
                ><CapTranslation translation="scoring_page.repayment"
              /></v-card-text>
              <b>{{ repaymentDesc }}</b>
            </v-card>
          </div>
          <div class="cap-col">
            <v-card class="text-center v-card--primary v-card--disabled">
              <v-card-text
                ><CapTranslation translation="scoring_page.interest"
              /></v-card-text>
              <b>{{ interest }}%</b>
            </v-card>
          </div>
        </div>
      </div>
      <div class="cap-col">
        <CapSpeedometer :value="scoreValue" />
      </div>
    </div>
    <div class="cap-col cap-col-12" v-if="scoreValue <= 1">
      <p v-html="conclusion"></p>
      <p><CapTranslation translation="scoring_page.contact.title" /></p>
      <p><CapTranslation translation="scoring_page.contact.description" /></p>
    </div>
    <div class="cap-col cap-col-12" v-if="scoreValue > 1">
      <p v-html="conclusion"></p>
      <div class="cap-col" :class="'cap-col-'+btnCols()">
        <v-btn
          class="v-btn--primary"
          block
          v-if="!emailSent || useEntrepreneurFlow"
          @click="nextStep()"
          ><CapTranslation translation="financier_modal.send"
        /></v-btn>
      </div>
      <CapTranslation style="margin-top:15px;display:block;" translation="scoring_page.ing_advisor_text" v-if="this.$store.getters.ident == 'ingref'"/>
    </div>
    <div class="cap-col cap-col-12">
      <p class="subtitle">
        <CapTranslation translation="scoring_page.disclaimer" />
      </p>
    </div>
    <v-dialog v-model="show" :width="popupWidth" :persistent="true">
      <v-card width="100%">
        <v-card-title class="dialog-title" v-html="questionHeader"></v-card-title>
        <div class="resetWidth">
          <div class="cap-layout pl-3 question-dialog-content">
          <FormField
            :item="questions[activeQuestion]"
            :fieldName="activeQuestion"
            :formValue="
              questionValues[activeQuestion]
                ? questionValues[activeQuestion].toString()
                : ''
            "
            @update-field="update"
            @update-next="update"
          />
          </div>
          <div class="popup-error-box">
            <CapTranslation translation="scoring_page.popup.error" v-if="showPopupError"/>
          </div>
        </div>
        <v-card-actions>
        <div class="cap-layout dialog-buttons-layout">
            <v-btn class="v-btn--secondary" @click="closePopup">
              <CapTranslation translation="scoring_page.popup.button.cancel" />
            </v-btn>
            <v-btn @click="updateQuestion()" class="v-btn--primary">
              <CapTranslation translation="scoring_page.popup.button.save" />
            </v-btn>
        </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import CapTranslation from "./CapTranslation.vue";
import CapSpeedometer from "./CapSpeedometer.vue";
import CapResetButton from "./CapResetButton.vue";
import FormField from "./FormType/FormField.vue";

export default {
  name: "CapScoring",
  props: {
    resetForm: Function,
    resetClick: Function,
    isScoringPage: Boolean,
    questions: Object,
    questionValues: Object,
    nextStep: Function,
    step: Number,
    locked: Boolean,
    useEntrepreneurFlow: Boolean
  },
  components: {
    CapTranslation,
    CapSpeedometer,
    CapResetButton,
    FormField,
  },
  data: () => ({
    financingAmount: 0,
    duration: "",
    repayment: "",
    income: 0,
    objectValue: 0,
    durationQuestion: "",
    financingQuestion: "",
    repaymentQuestion: "",
    objectValueQuestion: "",
    incomeQuestion: "",
    show: false,
    activeQuestion: {},
    activeValue: "",
    scoreValue: 0,
    savedString: "",
    showPopupError: false,
  }),
  computed: {
    flexDir() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "column-reverse";
        case "sm":
          return "column-reverse";
        default:
          return "row";
      }
    },
    spedCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
        case "lg":
          return "12";
        default:
          return "5";
      }
    },
    popupWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        default:
          return "50%";
      }
    },
    score() {
      return this.$store.getters.score;
    },
    maxAmount() {
      return this.score ? this.score.maxAmount : 0;
    },
    interest() {
      return this.score ? this.score.interest : 0;
    },
    chanceOfSuccess() {
      return this.score ? this.score.chanceOfSuccess : "";
    },
    loanToValue() {
       return this.score ? this.score.loanToValue : 0;
    },
    debtYield() {
      return this.score ? this.score.debtYield : 0;
    },
    debtServiceCoverageRatio() {
      return this.score ? this.score.debtServiceCoverageRatio : 0;
    },
    questionHeader() {
      return this.questions[this.activeQuestion] ?
        this.questions[this.activeQuestion].header : "";
    },
    durationDesc() {
      var choices = this.durationQuestion
        ? this.getChoices(this.questions[this.durationQuestion])
        : null;
      return choices ? choices[this.duration] : "";
    },
    repaymentDesc() {
      var choices = this.repaymentQuestion
        ? this.getChoices(this.questions[this.repaymentQuestion])
        : null;
      return choices ? choices[this.repayment] : "";
    },
    emailSent() {
      return this.$store.getters.saveFormFieldsStatusSuccess;
    },
    conclusion(){
      return this.score? this.score.conclusion : '';
    },
  },
  methods: {
    formatWithEuroSign(questionAmount) {
      const amount = this.formatMoney(questionAmount);
      return "<b>&euro;"+amount+"</b>";
    },
    setData: function () {
      this.scoreValue = 0;
      if (this.financingQuestion === "") {
        var questionIds = Object.keys(this.questions);
        questionIds.forEach((element) => {
          if (this.questions[element].mappingLabel === "FINANCING_AMOUNT") {
            this.financingQuestion = element;
          }

          if (this.questions[element].mappingLabel === "INCOME") {
            this.incomeQuestion = element;
          }

          if (this.questions[element].mappingLabel === "OBJECT_VALUE") {
            this.objectValueQuestion = element;
          }

          if (this.questions[element].mappingLabel === "DURATION") {
            this.durationQuestion = element;
          }

          if (this.questions[element].mappingLabel === "REPAYMENT") {
            this.repaymentQuestion = element;
          }
        });
      }
      this.repayment = this.questionValues[this.repaymentQuestion];
      this.duration = this.questionValues[this.durationQuestion];
      this.financingAmount = this.questionValues[this.financingQuestion];
      this.objectValue = this.questionValues[this.objectValueQuestion];
      this.income = this.questionValues[this.incomeQuestion];
      this.$store.dispatch("getScore", {
        income: this.income,
        financingAmount: this.financingAmount,
        objectValue: this.objectValue,
        duration: this.duration,
        repayment: this.repayment,
      }).then(() => {
        // Remove the arrow to go back by "locking" the page when the score is insufficient
        if (this.score.trafficLight <= 1) {
          this.$emit('lock-page');
        }
      });
    },
    saveAllData() {
      // Only save everything is one of the answers has changed
      // We can check this by creating 1 string from all answers, and comparing it to the last string
      if (JSON.stringify(this.questionValues) != this.savedString || this.savedString == "") {
        this.$emit('save-form-fields', true);
        this.savedString = JSON.stringify(this.questionValues);
      }
    },
    openQuestion(key) {
      this.activeQuestion = key;
      this.activeValue = this.questionValues[key];
      this.show = true;
      this.scoreValue = 0;
    },
    updateQuestion() {
      if (!this.activeValue || this.activeValue == 0) {
        this.showPopupError = true;
      } else {
        this.showPopupError = false;
        this.$emit("update-field", this.activeQuestion, this.activeValue);
        this.$emit("activate-question", this.questions[this.activeQuestion]);
        this.setData();
        this.show = false;
      }
    },
    update(key, value) {
      this.activeValue = value;
    },
    closePopup() {
      this.scoreValue = this.score.trafficLight;
      this.show = false;
    },
  },
  watch: {
    isScoringPage(value) {
      if (value) {
        this.setData();
      }
    },
    score(value) {
      this.scoreValue = value ? value.trafficLight : 0;
      this.$store.dispatch("setFinancierList", {
        fields: this.questionValues
      });
      if (this.useEntrepreneurFlow) {
        this.saveAllData();
      }
    },
  },
};
</script>
<style scoped>
.question-dialog-content .v-card {
  width:100% !important;
}
.resetWidth {
  width:auto !important;
}
.v-dialog {
  overflow-y:initial !important;
}
.popup-error-box {
  position:absolute;
  left:15px;
  margin-top:-30px;
  color:#ff5252 !important;
}
</style>