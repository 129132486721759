<template>
  <v-footer padless>
    <v-card
      class="flex"
      tile
      :style="getStyle()"
      :min-height="!isLastStep? '30px': 0"
    >
      <v-card-title v-if="showCarousel && !isLastStep">
        <CapCarousel />
      </v-card-title>
      <v-card-text style="flex: 0;"
        class="py-2 text-center"
      >
        <CapTranslation v-if="showFooter"
          translation="footer.powered_by"
        /><a target="_blank" v-if="showFooter" href="https://www.capsearch.com"
          >www.capsearch.com</a
        >
        <span class="footer-version text-right">v{{ version }}</span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<style scoped>

</style>
<script>
import CapCarousel from "./CapCarousel.vue";
import CapTranslation from "./CapTranslation.vue";
const version = require("../../package.json").version;
export default {
  name: "LeadgeneratorFooter",
  components: {
    CapCarousel,
    CapTranslation,
  },
  props: {
    isLastStep: Boolean,
    showCarousel: Boolean,
    showFooter: Boolean
  },
  computed: {
    version() {
      return version;
    }
  },
  methods:{
      getStyle: function(){
          var style = {'-ms-overflow-x': 'hidden'};
          if(!this.showCarousel || this.isLastStep){
              style['display'] = 'flex';
              style['flex-direction'] = 'column-reverse';
          }

          return style;
      }
  },
  data() {
    return {};
  },
};
</script>
