<template>
  <v-card width="100%">
    <v-card-title class="dialog-title" v-html="contactFormHeader"></v-card-title>
    <v-card-text>
      <div class="pb-4" v-html="contactFormContent"></div>
      <div class="cap-layout cap-layout-row cap-layout-wrap align-center" style="display: -webkit-box">
        <div class="cap-col cap-col-12">
          <v-form v-model="valid" ref="contactForm">
            <FormField
              :item="item"
              v-for="(item, key) in this.fields"
              :key="`${key}-content`"
              :fieldName="key"
              :formValue="getFieldValue(key)"
              @update-field="update"
              :ruleNames="getRules(item)"
              :contactPage="true"
            />
          </v-form>
        </div>
      </div>
      <span v-html="privacyPolicy"></span>
    </v-card-text>
    <v-card-actions>
          <div class="cap-layout dialog-buttons-layout">
              <v-btn class="v-btn--secondary" @click="onClick()" v-if="showCancel">
                <CapTranslation translation="contact_form.cancel" />
              </v-btn>
              <v-btn class="v-btn--primary" v-if="!emailSent" @click="checkAndSave">
                <CapTranslation translation="contact_form.save" />
              </v-btn>
          </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import FormField from "./FormType/FormField.vue";
import CapTranslation from "./CapTranslation.vue";

export default {
  name: "CapContactForm",
  props: {
    contactForm: Object,
    allFormValues: Object,
    showCancel: Boolean,
  },
  data: () => ({
    formValues: {},
    fieldValue: "",
    valid: true,
  }),
  computed: {
    fields() {
      return this.contactForm && this.isset(this.contactForm, "properties")
        ? this.contactForm.properties
        : {};
    },
    privacyPolicy() {
      if (this.$store.getters.formFields) {
        var e = document.createElement("textarea");
        e.innerHTML = this.$store.getters.formFields.contactFormPrivacyPolicy;
        // handle case of empty input
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      }

      return "";
    },
    contactFormHeader() {
      return this.$store.getters.formFields
        ? this.$store.getters.formFields.contactFormHeader
        : "";
    },
    contactFormContent() {
      return this.$store.getters.formFields
        ? this.$store.getters.formFields.contactFormContent
        : "";
    },
    financiers() {
      return this.$store.getters.financierList;
    },
    emailSent() {
      return this.$store.getters.saveFormFieldsStatusSuccess;
    },
    score() {
      return this.$store.getters.score;
    },
    maxAmount() {
      return this.score ? this.score.maxAmount : 0;
    },
    interest() {
      return this.score ? this.score.interest : 0;
    },
    chanceOfSuccess() {
      return this.score ? this.score.chanceOfSuccess : "";
    },
    loanToValue() {
       return this.score ? this.score.loanToValue : 0;
    },
    debtYield() {
      return this.score ? this.score.debtYield : 0;
    },
    debtServiceCoverageRatio() {
      return this.score ? this.score.debtServiceCoverageRatio : 0;
    },
    trafficLight() {
      return this.score ? this.score.trafficLight : "";
    },
    savedData() {
      return {
        answers: this.allFormValues,
        financiers: this.financiers,
        contactForm: this.formValues,
        isFinal: true
      };
    }
  },
  watch: {
    dialog(val) {
      this.showDialog = val;
    },
  },
  components: {
    FormField,
    CapTranslation,
  },
  methods: {
    sendData: function () {
      this.$store.dispatch("saveFormFields", this.savedData);
      this.$store.dispatch("addStatistic", {
        action: this.$store.getters.statisticsActionFinish
      });
      if (this.useGoogleAnalytics) {
        this.$analytics.trackEvent('Click', 'Contact form', 'Contact form sent');
      }
    },
    onClick() {
      this.$emit("onClick");
    },
    update(key, value) {
      this.formValues[key] = value;
    },
    getFieldValue(key) {
      return this.isset(this.formValues, key) ? this.formValues[key] : "";
    },
    getRules(item) {
      return this.getFieldRules(item);
    },
    checkAndSave() {
      if (this.$refs.contactForm.validate()) {
        this.onClick();
        this.sendData();
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  color:inherit !important;
}
/* Get the contact form to be a bit more compact */
.v-dialog > .v-card > .v-card__text {
    padding: 0;
    padding-right:24px;
    padding-left:24px;
    padding-bottom:0px;
}

.v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>