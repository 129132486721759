<template>
  <span v-html="translated"> </span>
</template>
<script>
export default {
  name: "CapTranslation",
  props: {
    translation: String,
    params: Object,
  },
  computed: {
    translated() {
      return this.translate(this.translation, this.params);
    },
  },
};
</script>
