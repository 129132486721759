<template>
  <v-dialog v-model="show" :width="width">
    <v-card>
      <v-card-title class="dialog-title" v-html="dialogHeaderCal"></v-card-title>
      <v-card-text v-html="dialogTextCal"></v-card-text>
      <v-card-actions>
        <div class="cap-layout dialog-buttons-layout">
          <v-btn class="v-btn--secondary" @click="show = false">
            <CapTranslation translation="buttons.cancel.label" />
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CapTranslation from "./CapTranslation.vue";
export default {
  name: "ValidationDialog",
  props: {
    activeQuestion: Object,
    isScoring: Boolean,
  },
  data: () => ({
    show: false,
  }),
  computed: {
    // This popup is used for the validation but also for the generic ("algemeen") information
    // So we have to check 2 values, if there's no active question text we get the generic one
    dialogTextCal() {
      return this.dialogText && this.dialogText != ""
        ? this.dialogText
        : this.$store.getters.formFields &&
          this.$store.getters.formFields.validationText
        ? this.$store.getters.formFields.validationText
        : "";
    },
    dialogHeaderCal() {
      return this.dialogHeader && this.dialogHeader != ""
        ? this.dialogHeader
        : this.$store.getters.formFields &&
          this.$store.getters.formFields.validationHeader
        ? this.$store.getters.formFields.validationHeader
        : "";
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "70%";
        default:
          return "50%";
      }
    },
    dialogText() {
      return this.activeQuestion ? this.activeQuestion.validationText : "";
    },
    dialogHeader() {
      return this.activeQuestion ? this.activeQuestion.validationHeader : "";
    },
    financiersNum() {
      return this.$store.getters.financiersNum;
    },
  },
  watch: {
    financiersNum(newValue) {
      if (newValue != -1 && !this.show) {
        this.show = newValue == 0;
      }
    },
  },
  components: {
    CapTranslation,
  },
};
</script>
