// mutations
const mutations = {
    SET_STATISTICS_STATUS_PENDING(state, payload) {
        state.statisticsStatusPending = payload;
    },
    SET_STATISTICS_STATUS_SUCCESS(state, payload) {
        state.statisticsStatusSuccess = payload;
    },
    SET_STATISTICS_STATUS_FAIL(state, payload) {
        state.statisticsStatusFail = payload;
    },
}

export default mutations;