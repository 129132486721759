// mutations
const mutations = {
    SET_IDENT(state, payload) {
        state.ident = payload;
    },
    SET_SESSION(state, payload) {
        state.session = payload;
    },
}

export default mutations;