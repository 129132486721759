// actions
import axios from "axios";
const actions = {
    saveFormFields(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;
        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 POST requests to /api/leadgenerator/v1/save'+ident+ ' ('+session+')'});
            return;
        }
        context.commit("SAVE_FORM_FIELDS_STATUS_PENDING", true);
        context.commit("SAVE_FORM_FIELDS_STATUS_SUCCESS", false);
        context.commit("SAVE_FORM_FIELDS_STATUS_FAIL", false);
        data["session"] = session;
        axios
            .post(
                process.env.VUE_APP_CAPSEARCH_URL +
                "/api/leadgenerator/v1/save" +
                ident,
                data,
                { headers: { 'Content-Type': 'application/json' } }
            )
            //success
            .then(() => {
                if (data.isFinal) {
                    context.commit("SAVE_FORM_FIELDS_STATUS_SUCCESS", true);
                    context.commit("SAVE_FORM_FIELDS_STATUS_FAIL", false);
                }
            })
            //fail
            .catch(() => {
                this.dispatch("saveFormFields", {
                    answers: data.answers,
                    financiers: data.financiers,
                    contactForm: data.contactForm,
                    retry: data.retry ? data.retry + 1 : 1,
                    isFinal: data.isFinal ? true : false
                });

                // context.commit("SAVE_FORM_FIELDS_STATUS_SUCCESS", false);
                // context.commit("SAVE_FORM_FIELDS_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SAVE_FORM_FIELDS_STATUS_PENDING", false);
            });
    },
    async resetSessionID(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;
        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 GET requests to /api/leadgenerator/v1/reset-session'+ident+ ' ('+session+')'});
            return;
        }

        return axios
            .get(
                process.env.VUE_APP_CAPSEARCH_URL +
                "/api/leadgenerator/v1/reset-session" +
                ident
            )
            //success
            .then(({ data }) => {
                context.dispatch("setSession", data['session']);
            }).catch(() => {
                this.dispatch("resetSessionID", {
                    retry: data.retry ? data.retry + 1 : 1
                });
            });
    },
    setFormFields(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;
        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 GET requests to /api/leadgenerator/v1/fields'+ident+ ' ('+session+')'});
            return;
        }

        context.commit("SET_FORM_FIELDS_STATUS_PENDING", true);
        axios
            .get(
                process.env.VUE_APP_CAPSEARCH_URL +
                "/api/leadgenerator/v1/fields" +
                ident
            )
            //success
            .then(({ data }) => {
                context.commit("SET_FORM_FIELDS", data);
                if (data['session']) {
                    context.dispatch("setSession", data['session']);
                }
                context.commit("SET_FORM_FIELDS_STATUS_SUCCESS", true);
                context.commit("SET_FORM_FIELDS_STATUS_FAIL", false);
            })
            //fail
            .catch(() => {
                this.dispatch("setFormFields", {
                    retry: data.retry ? data.retry + 1 : 1
                });
                // context.commit("SET_FORM_FIELDS", {});
                // context.commit("SET_FORM_FIELDS_STATUS_SUCCESS", false);
                // context.commit("SET_FORM_FIELDS_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SET_FORM_FIELDS_STATUS_PENDING", false);
            });
    },

}

export default actions;