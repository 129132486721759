// mutations
const mutations = {
    SET_TRANSLATIONS(state, payload) {
        state.translations = payload;
    },
    SET_TRANSLATIONS_STATUS_PENDING(state, payload) {
        state.translationsStatusPending = payload;
    },
    SET_TRANSLATIONS_STATUS_SUCCESS(state, payload) {
        state.translationsStatusSuccess = payload;
    },
    SET_TRANSLATIONS_STATUS_FAIL(state, payload) {
        state.translationsStatusFail = payload;
    },
}

export default mutations;