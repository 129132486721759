// actions
import axios from "axios";
const actions = {
    addStatistic(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;

        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 POST requests to /api/leadgenerator/v1/statistics'+ident+ ' ('+session+')'});
            return;
        }

        data.session = context.getters.session;
        context.commit("SET_STATISTICS_STATUS_PENDING", true);
        axios
        .post(
            process.env.VUE_APP_CAPSEARCH_URL +
            "/api/leadgenerator/v1/statistics" +
            ident,
            data,
            { headers: { 'Content-Type': 'application/json' } }
            )
            .then(() => {
                context.commit("SET_STATISTICS_STATUS_SUCCESS", true);
                context.commit("SET_STATISTICS_STATUS_FAIL", false);
            })
            .catch(() => {
                this.dispatch("addStatistic", {
                    action: data.action ? data.action : '',
                    arg: data.arg ? data.arg : '',
                    session: data.session,
                    retry: data.retry ? data.retry + 1 : 1
                });
                context.commit("SET_STATISTICS_STATUS_SUCCESS", false);
                context.commit("SET_STATISTICS_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SET_STATISTICS_STATUS_PENDING", false);
            });
    },
}
export default actions;