// getters
const getters = {
    translations(state) {
        return state.translations;
    },
    transLoaded(state){
        return state.translationsStatusSuccess;
    }
}

export default getters;