<template>
  <div class="cap-layout">
    <div class="cap-col" :class="'cap-col-'+btnCols()">
      <v-btn class="mx-1 v-btn--primary" block @click.stop="dialog = true"
        ><CapTranslation translation="reset_button.label"
      /></v-btn>
    </div>
    <v-dialog v-model="dialog" :width="width">
      <v-card>
        <v-card-title class="dialog-title">
        <CapTranslation translation="reset_button.modal.title"/>
        </v-card-title>
        <v-card-text>
          <CapTranslation translation="reset_button.modal.description" />
        </v-card-text>
        <v-card-actions>
          <div class="cap-layout dialog-buttons-layout">

              <v-btn class="v-btn--secondary" @click="dialog = false">
                <CapTranslation translation="reset_button.modal.cancel" />
              </v-btn>

              <v-btn class="v-btn--primary"
                @click="resetClick(); dialog = false;">
                <CapTranslation translation="reset_button.modal.agree" />
              </v-btn>

          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CapTranslation from "./CapTranslation.vue";
export default {
  name: "CapResetButton",
  data: () => ({
    dialog: false,
  }),
  props: {
    resetClick: Function,
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "70%";
        default:
          return "50%";
      }
    },
  },
  components: {
    CapTranslation,
  },
};
</script>
