<template>
  <v-card min-height="40vh">
    <v-card>
      <div class="cap-row">
        <div class="cap-col cap-col-1">
          <v-btn
            class="v-btn--secondary"
            v-if="!emailSent"
            icon
            @click="prevStep()"
          >
            <v-icon x-large>navigate_before</v-icon>
          </v-btn>
        </div>
        <CapResetButton :resetClick="resetClick" />
        <v-spacer v-if="spacer" />
        <div class="cap-col" :class="'cap-col-'+btnCols()-1">
            <v-btn
              class="v-btn--primary"
              block
              v-if="!emailSent"
              @click="openContactForm">
                <CapTranslation translation="financier_modal.send"/>
              </v-btn>
        </div>
        <div class="cap-col cap-col-1" :class="'text-right ' + (btnCols() == 5 ? 'mr-2' : '')">
          <v-btn
            class="v-btn--secondary"
            v-if="showNext && !emailSent"
            icon
            @click="nextStep()">
            <v-icon x-large>navigate_next</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card class="financier-header v-table--header">
      <div class="cap-row align-center justify-center">
        <div class="cap-col cap-col-3 px-10"  v-if="btnCols() < 5">
          <CapTranslation translation="financier_modal.provider"/>
        </div>
        <div class="cap-col cap-col-2" v-if="btnCols() < 5">
          <CapTranslation translation="financier_modal.product"/>
        </div>
        <div class="cap-col cap-col-5 px-10" v-if="btnCols() > 4">
          <CapTranslation translation="financier_modal.provider" />
          &nbsp;
          <CapTranslation translation="financier_modal.product" />
        </div>
        <div class="cap-col cap-col-2">
          <CapTranslation translation="financier_modal.interest"/>
        </div>
        <div class="cap-col cap-col-2">
          <CapTranslation translation="financier_modal.duration"/>
        </div>
        <div class="cap-col cap-col-3 pr-10">
          <CapTranslation translation="financier_modal.score"/>
        </div>
      </div>
    </v-card>
    <v-card v-for="item in this.financiers" :key="item.id" class="my-1 py-5">
      <div class="cap-row text-center">
        <div class="cap-col cap-col-3 px-10" v-if="btnCols() < 5">
          <v-img
            aspect-ratio="5"
            min-height="50px"
            v-if="item.logo != null"
            :src="getLogo(item.logo)"
            contain>
            </v-img>
        </div>
        <div class="cap-col cap-col-2" v-if="btnCols() < 5">
          {{ item.name }}
        </div>
        <div class="cap-col cap-col-5 px-10" v-if="btnCols() > 4">
          <v-img
            aspect-ratio="5"
            min-height="50px"
            v-if="item.logo != null"
            :src="getLogo(item.logo)"
            contain>
            </v-img>
          {{ item.name }}
        </div>
        <div class="cap-col cap-col-2">
          {{ item.minRange + " - " + item.maxRange }}<br />{{
            item.rangePer !== "" ? "Per " + item.rangePer : ""
          }}
        </div>
        <div class="cap-col cap-col-2">
          {{ item.expectedLeadTime }}
        </div>
        <div class="cap-col cap-col-3 pr-10">
          <v-progress-circular
            color="primary"
            :rotate="360"
            :size="65"
            :width="10"
            :value="item.percent">
            {{ item.percent }}%
          </v-progress-circular>
        </div>
      </div>
      <div class="cap-row">
        <div class="cap-col cap-col-12 text-center">
          <v-btn class="v-btn--primary-inverted" @click="toggleExpanded(item.id)">
            <CapTranslation translation="financier_modal.more_information" />
            <v-icon v-show="refresh && expanded[item.id]">expand_less</v-icon>
            <v-icon v-show="refresh && !expanded[item.id]">expand_more</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="cap-row v-table--sub-header" v-if="refresh && expanded[item.id]">
        <div class="cap-col cap-col-4 px-10">
          <CapTranslation translation="financier_modal.documents"/>
        </div>
        <div class="cap-col cap-col-4 px-10">
          <CapTranslation translation="financier_modal.conditions"/>
        </div>
        <div class="cap-col cap-col-4 px-10">
          <CapTranslation translation="financier_modal.cost"/>
        </div>
      </div>
      <div class="cap-row" v-if="refresh && expanded[item.id]">
        <div class="cap-col cap-col-4 px-10">
          <ul style="padding: 0; list-style: none">
            <li v-for="(doc, index) in item.documents" :key="index">
              <v-icon class="v-icon--primary">check</v-icon> {{ doc }}
            </li>
          </ul>
        </div>
        <div class="cap-col cap-col-4 px-10">
          <ul style="padding: 0; list-style: none" v-if="item.conditions != ''">
            <li v-for="(condition, index) in item.conditions" :key="index">
              <span class="d-inline" v-html="condition"></span>
            </li>
          </ul>
        </div>
        <div class="cap-col cap-col-4 px-10">
          <ul style="padding: 0; list-style: none" v-if="item.otherCosts != ''">
            <li v-for="(otherCost, index) in item.otherCosts" :key="index">
              <span class="d-inline" v-html="otherCost"></span>
            </li>
          </ul>
        </div>
      </div>
    </v-card>
  </v-card>
</template>
<script>
import CapResetButton from "./CapResetButton.vue";
import CapTranslation from "./CapTranslation.vue";

export default {
  name: "FinanciersModal",
  props: {
    formValues: Object,
    showSendButton: Boolean,
    currentQuestionIndex: Number,
    contactForm: Object,
    steps: Number,
    resetForm: Function,
    resetClick: Function,
    prevStep: Function,
    nextStep: Function,
    showNext: Boolean,
    useEntrepreneurFlow: Boolean,
    useGoogleAnalytics: Boolean
  },
  data: () => ({
    expanded: {},
    refresh: true,
    formOpened: false,
  }),
  computed: {
    financiers() {
      return this.$store.getters.financierList;
    },
    emailSent() {
      return this.$store.getters.saveFormFieldsStatusSuccess;
    },
    spacer() {
      return this.btnCols() < 5 ? true : false;
    },
  },
  watch: {
    currentQuestionIndex: {
      immediate: true,
      handler: "setFormTimeout",
    }
  },
  methods: {
    toggleExpanded: function (id) {
      this.refresh = false;
      this.refresh = true;
      this.expanded[id] = this.expanded[id] == undefined || !this.expanded[id];
    },
    openContactForm: function () {
      this.$emit("update-show-contact-form");
      this.formOpened = true;
    },
    getLogo: function (logo) {
      return process.env.VUE_APP_CAPSEARCH_MEDIA + "/" + logo;
    },
    setFormTimeout: function (val) {
      if (val == this.steps + 1 && !this.useEntrepreneurFlow) {
        var $this = this;
        setTimeout(function () {
          if (!$this.formOpened && $this.currentQuestionIndex == $this.steps + 1) {
            $this.$emit("update-show-contact-form");
          }
        }, 15000);
      }
    },
  },
  mounted() {
    if (this.useGoogleAnalytics) {
      this.$analytics.trackEvent('Answer', 'Finished', 'Finished all questions');
    }
  },
  components: {
    CapResetButton,
    CapTranslation,
  },
};
</script>
<style scoped>
.col {
  flex: 1 0 0;
}
</style>
