// actions
import axios from "axios";
const actions = {
    setTranslations(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;

        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 POST requests to /api/leadgenerator/v1/translations'+ident+ ' ('+session+')'});
            return;
        }
        
        context.commit("SET_TRANSLATIONS_STATUS_PENDING", true);
        axios
            .get(process.env.VUE_APP_CAPSEARCH_URL + "/api/leadgenerator/v1/translations")
            //success
            .then(({ data }) => {
                context.commit("SET_TRANSLATIONS", data);
                context.commit("SET_TRANSLATIONS_STATUS_SUCCESS", true);
                context.commit("SET_TRANSLATIONS_STATUS_FAIL", false);
            })
            //fail
            .catch(() => {
                this.dispatch("setTranslations", {
                    retry: data.retry ? data.retry + 1 : 1
                });
                // context.commit("SET_TRANSLATIONS", {});
                // context.commit("SET_TRANSLATIONS_STATUS_SUCCESS", false);
                // context.commit("SET_TRANSLATIONS_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SET_TRANSLATIONS_STATUS_PENDING", false);
            });
    },
}
export default actions;