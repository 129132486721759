<template>
  <v-text-field
    :error="errors"
    outlined
    v-model="fieldValue"
    type="number"
    :label="label"
    suffix="%"
    @keyup.enter.native="nextStep()"
  ></v-text-field>
</template>
<script>
export default {
  name: "CapPercentInput",
  props: {
    nextStep: Function,
    fieldName: String,
    label: String,
    errors: Boolean,
    value: String,
  },
  data: () => ({
    fieldValue: "",
  }),
  watch: {
    fieldValue: function (value) {
      if (value > 100) {
        value = 100;
      }

      if (value < 0) {
        value = 0;
      }

      this.$emit("update-number", this.fieldName, value);
    },
    value: function (value) {
      this.fieldValue = value;
    },
  },
  methods: {
    resetForm() {
      this.fieldValue = '0';
      this.$emit("update-number", this.fieldName, this.fieldValue);
    },
  },
  mounted() {
    this.fieldValue = this.value;
  },
};
</script>
