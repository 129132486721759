<template>
  <v-dialog v-model="dialog" width="400px" height="300px">
    <v-card>
      <v-card-title><CapTranslation translation="browsercheck.title" /></v-card-title>
      <v-card-text><CapTranslation translation="browsercheck.text" /></v-card-text>
      <v-card-actions>
          <div class="cap-layout dialog-buttons-layout">
              <v-btn class="v-btn--primary" @click="closeDialog">
                <CapTranslation translation="browsercheck.button" />
              </v-btn>
          </div>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CapTranslation from "./CapTranslation.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  name: "CapBrowserCheck",
  props: {
    browserPopupTitle: String,
    browserPopupText: String
  },
  created() {
      this.BrowserCheck();
  },
  methods: {
    BrowserCheck() {
        // For all kinds of browser checks, look here
        // https://jsfiddle.net/6spj1059/

        // Internet Explorer 6-11
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
            this.dialog = true;
        }
    },
    closeDialog: function () {
        this.dialog = false
    },
  },
  components: {
    CapTranslation,
  },
};
</script>