import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const authentication = {
    namespaced: false,
    // initial state
    state: {
        ident: null,
        session: null,
    },
    getters,
    mutations,
    actions
};

export default authentication;