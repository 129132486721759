<template>
  <v-textarea
    v-model="fieldValue"
    :label="label"
    outlined
    :name="fieldName"
    :error="errors"
  ></v-textarea>
</template>
<script>
export default {
  name: "CapTextInput",
  props: {
    fieldName: String,
    hover: String,
    label: String,
    errors: Boolean,
    rules: Array
  },
  data: () => ({
    fieldValue: "",
  }),
  watch: {
    fieldValue: function(value) {
      this.$emit("update-text", this.fieldName, value);
    },
  },
  methods: {
    resetForm() {
      this.fieldValue = "";
      this.$emit("update-text", this.fieldName, this.fieldValue);
    },
  },
};
</script>
