import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const fields = {
    namespaced: false,
    // initial state
    state: {
        formFields: null,
        formFieldsStatusPending: null,
        formFieldsStatusSuccess: null,
        formFieldsStatusFail: null,
        saveFormFieldsStatusPending: null,
        saveFormFieldsStatusSuccess: null,
        saveFormFieldsStatusFail: null,
    },
    getters,
    mutations,
    actions
};

export default fields;