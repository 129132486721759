<template>
  <div :class="padding">
    <div class="cap-row" v-if="cols > 3">
      <CapResetButton :resetClick="resetClick" />
    </div>
    <div class="cap-layout cap-layout-row cap-layout-wrap align-center justify-center">
      <div class="cap-col" :class="'cap-col-'+cols">
        <b>{{ startText }}</b>
      </div>
      <CapResetButton v-if="cols <= 3" :resetClick="resetClick" />
      <v-spacer />
      <div class="cap-col text-right" :class="'cap-col-'+cols">
        <b>{{ endText }}</b>
      </div>
    </div>
    <div class="cap-layout cap-layout-row cap-layout-wrap align-center justify-center">
      <div class="cap-col cap-col-12">
        <v-progress-linear
          :value="percent"
          height="25"
          :color="progressBarColor">
          <template v-slot="{ value }"> {{ Math.ceil(value) }}% </template>
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>
<script>
import CapResetButton from "./CapResetButton.vue";

export default {
  name: "ProgressBar",
  props: {
    totalSteps: Number,
    activeStep: Number,
    resetClick: Function,
  },
  computed: {
    progressBarColor() {
      return this.$store.getters.formFields.style
        ? this.$store.getters.formFields.style.progressBarColor
        : "";
    },
    percent: {
      get: function () {
        return ((this.activeStep - 1) * 100) / this.totalSteps;
      },
      set: function (value) {
        this.percent = value;
      },
    },
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 4;
        default:
          return 3;
      }
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "pa-2";
        case "sm":
          return "pa-2";
        default:
          return "pa-10";
      }
    },
    startText() {
      return this.$store.getters.formFields
        ? this.$store.getters.formFields.startText
        : "";
    },
    endText() {
      return this.$store.getters.formFields
        ? this.$store.getters.formFields.endText
        : "";
    },
  },
  components: {
    CapResetButton,
  },
};
</script>
<style>

</style>
