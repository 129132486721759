// getters
const getters = {
    financierList(state) {
        return state.financierList;
    },
    allFinOrgs(state) {
        return state.allFinOrgs;
    },
    financiersNum(state){
        return state.financiersNum;
    },
    finLoaded(state){
        return state.financierListStatusSuccess;
    }
}

export default getters;