import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const scoring = {
    namespaced: false,
    // initial state
    state: {
        score: null,
        scoreStatusPending: null,
        scoreStatusSuccess: null,
        scoreStatusFail: null
    },
    getters,
    mutations,
    actions
};

export default scoring;