// actions
import axios from "axios";
const actions = {
    async getScore(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;

        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 POST requests to /api/leadgenerator/v1/rating'+ident+ ' ('+session+')'});
            return;
        }

        context.commit("SET_SCORE", {});
        context.commit("GET_SCORE_STATUS_PENDING", true);
        context.commit("GET_SCORE_STATUS_SUCCESS", false);
        context.commit("GET_SCORE_STATUS_FAIL", false);
        return axios
            .post(
                process.env.VUE_APP_CAPSEARCH_URL + "/api/leadgenerator/v1/rating" + ident,
                data
            )
            //success
            .then(({ data }) => {
                context.commit("SET_SCORE", data);
                context.commit("GET_SCORE_STATUS_SUCCESS", true);
                context.commit("GET_SCORE_STATUS_FAIL", false);
            })
            //fail
            .catch(() => {
                this.dispatch("getScore", {
                    income: data.income,
                    financingAmount: data.financingAmount,
                    objectValue: data.objectValue,
                    duration: data.duration,
                    repayment: data.repayment,
                    retry: data.retry ? data.retry + 1 : 1
                });
                // context.commit("GET_SCORE_STATUS_SUCCESS", false);
                // context.commit("GET_SCORE_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("GET_SCORE_STATUS_PENDING", false);
            });
    }
}

export default actions;