<template>
  <v-card flat>
    <div class="cap-row" style="padding: 0 12px;margin-right:0px;" v-if="!contactPage">
        <span :class="MoveQuestionRightOnSelect" v-html="item.title"/>
        <CapIcon
          color="primary"
          v-if="item.information && item.information !== ''"
          icon="info"
          :tooltipText="item.information"
          :id="fieldName"
        />
    </div>
    <span v-html="item.description"
      class="question__description"
      :class="MoveQuestionRightOnSelect"
      v-if="item.description && item.description !== ''"
    />
    <div class="cap-row" style="margin-right:0px;">
      <div class="cap-col cap-col-12">
        <CapAddressInput
          ref="inputField"
          v-if="item.type == 'address'"
          :step="index + 1"
          :fieldName="fieldName"
          @update-text="update"
          :label="placeholder"
          :errors="!valid"
          :errorOnInput="errorOnInput"
        />
        <CapBoxChoice
          ref="inputField"
          v-if="item.type == 'choice'"
          :choices="getChoices(item)"
          :step="index + 1"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-selected="updateNext"
          @update="update"
          :label="placeholder"
          :errors="!valid"
          :rules="activeRules"
          :errorOnInput="errorOnInput"
        />
        <CapCurrencyInput
          ref="inputField"
          @update-number="update"
          v-model="numValue"
          v-if="item.type == 'integer'"
          :nextStep="nextStep"
          :step="index + 1"
          :label="placeholder"
          :fieldName="fieldName"
          :errors="!valid"
          :rules="activeRules"
          :hint="item.hint"
        />
        <CapSelectChoice
          ref="inputField"
          v-if="item.type == 'select'"
          :choices="getChoices(item)"
          :step="index + 1"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-selected="updateNext"
          @update="update"
          :label="placeholder"
          :errors="!valid"
          :rules="activeRules"
        />
        <CapTextInput
          ref="inputField"
          v-if="item.type == 'string' && !contactPage"
          :nextStep="nextStep"
          :step="index + 1"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-text="update"
          :label="placeholder"
          :errors="!valid"
          :rules="activeRules"
          :hint="item.hint"
        />
        <CapContactTextInput
          ref="inputField"
          v-if="item.type == 'string' && contactPage"
          :nextStep="nextStep"
          :step="index + 1"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-text="update"
          :label="item.title"
          :errors="!valid"
          :rules="activeRules"
        />
        <CapTextEditor
          ref="inputField"
          v-if="item.type == 'textarea'"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-text="update"
          :label="placeholder"
          :errors="!valid"
          :rules="activeRules"
        />
        <CapPercentInput
          ref="inputField"
          v-if="item.type == 'percent'"
          :nextStep="nextStep"
          v-model="fieldValue"
          :fieldName="fieldName"
          @update-number="update"
          :label="placeholder"
          :errors="!valid"
        />
      </div>
    </div>
  </v-card>
</template>
<script>
import CapBoxChoice from "./CapBoxChoice.vue";
import CapCurrencyInput from "./CapCurrencyInput.vue";
import CapSelectChoice from "./CapSelectChoice.vue";
import CapTextInput from "./CapTextInput.vue";
import CapContactTextInput from "./CapContactTextInput";
import CapTextEditor from "./CapTextEditor.vue";
import CapPercentInput from "./CapPercentInput.vue";
import CapAddressInput from "./CapAddressInput.vue";
import CapIcon from "../CapIcon.vue";
export default {
  name: "FormField",
  props: {
    item: Object,
    nextStep: Function,
    fieldName: String,
    index: Number,
    formValue: String,
    placeholder: String,
    hint: String,
    ruleNames: Array,
    errorOnInput: Boolean,
    contactPage: Boolean,
    AnswerIsSelected: Function,
  },
  data: () => ({
    fieldValue: "",
    valid: true,
  }),
  methods: {
    updateNext(key, value) {
      this.fieldValue = value;
      this.$emit("update-next", this.fieldName, value);
    },
    update(key, value) {
      this.fieldValue = value;
      this.$emit("update-field", this.fieldName, value);
    },
    resetForm() {
      if (this.$refs.inputField != undefined) {
        this.$refs.inputField.resetForm();
      }
    },
    validate(key, value) {
      if (key == this.fieldName) {
        if (value == "" || parseInt(value) == 0 || value == undefined) {
          this.valid = false;
        } else {
          this.valid = true;
        }
      }
    },
  },
  watch: {
    formValue(value) {
      this.fieldValue = value;
    },
  },
  computed: {
    MoveQuestionRightOnSelect() {
      if (this.item.type == 'select') {
        return 'move-question-right';
      }
      return '';
    },
    numValue() {
      return parseInt(this.fieldValue);
    },
    activeRules() {
      var actRules = [];
      if (this.ruleNames) {
        this.ruleNames.forEach((element) => {
          actRules.push(this.rules[element]);
        });
      }
      return actRules;
    },
    rules() {
      return {
        email: (v) =>
          /.+@.+/.test(v) || this.translate("form.validation.email"),
        required: (v) => !!v || this.translate("form.validation.required"),
      };
    },
  },
  components: {
    CapBoxChoice,
    CapCurrencyInput,
    CapSelectChoice,
    CapTextInput,
    CapContactTextInput,
    CapTextEditor,
    CapPercentInput,
    CapIcon,
    CapAddressInput
  },
  mounted() {
    this.fieldValue = this.formValue;
  },
};
</script>
