import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import vueCustomElement from "vue-custom-element";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import "document-register-element/build/document-register-element";
import store from "./store";
import VueAnalytics from 'vue-ua'

Vue.config.productionTip = false;
Vue.use(vueCustomElement);
Vue.prototype.isset = function (array, key) {
    var keys = Object.keys(array);
    for (var i = 0; i < keys.length; i++) {
        if (keys[i] == key) {
            return true;
        }
    }
    return false;
};
Vue.prototype.fontSize = function (screen) {
    switch (screen) {
        case "xs":
            return "10px !important";
        case "sm":
            return "11px !important";
        default:
            return "12px !important";
    }
};
Vue.prototype.formatMoney = function (amount) {
    try {
        let thousands = '.';
        let decimal = ',';
        let decimalCount = 2;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2));
    } catch (e) {
        console.log(e)
    }
}

Vue.prototype.formatPercent = function (value) {
    try {
        let decimal = ',';
        let decimalCount = 1;
        let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
        return i + (decimal + Math.abs(value - i).toFixed(decimalCount).slice(2));
    } catch (e) {
        console.log(e)
    }
}

Vue.prototype.btnCols = function () {
    if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm" ||
        document.getElementsByClassName("v-application--wrap")[0].offsetWidth <
        600
    ) {
        return "5";
    }
    if (
        this.$vuetify.breakpoint.name == "md" ||
        document.getElementsByClassName("v-application--wrap")[0].offsetWidth <
        800
    ) {
        return "4";
    }

    return "3";
}
Vue.prototype.getChoices = function (item) {
    var choices = {};
    var keys = item["enum"];
    if (keys) {
        var values = keys.length > 0 ? item["enum_titles"] : [];
        for (var i = 0; i < keys.length; i++) {
            choices[keys[i]] = values[i];
        }
    }
    return choices;
}
Vue.prototype.getFieldRules = function (formField) {
    if (typeof (formField.attr) !== undefined && typeof (formField.attr.validations) !== undefined) {
        return formField.attr.validations;
    }

    return [];
}
Vue.prototype.translate = function (translation, params) {
    var trans = translation;
    if (this.$store.getters.translations != null) {
        var splited = translation.split(".");
        trans = this.$store.getters.translations;
        splited.forEach((element) => {
            trans = trans[element];
        });
    }

    if (params && trans !== translation) {
        var keys = Object.keys(params);
        for (var i = 0; i < keys.length; i++) {
            trans = trans.replace("%" + keys[i] + "%", params[keys[i]]);
        }
    }
    return trans;
};
Vue.prototype.maxWidth = function () {
    switch (this.$vuetify.breakpoint.name) {
        case "xs":
            return "100%";
        case "sm":
            return "100%";
        case "md":
            return "70%";
        case "lg":
            return "60%";
        case "xl":
            return "50%";
        default:
            return "50%";
    }
};

Vue.prototype.initGoogleAnalytics = function (trackingId, version) {
    Vue.use(VueAnalytics, {
        appName: 'Leadgenerator',
        appVersion: version,
        trackingId: trackingId,
        debug: true
    });
};

Vue.prototype.url = "http";
App.vuetify = vuetify;
App.store = store;
Vue.customElement("leadgenerator-widget", App);
