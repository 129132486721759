<template>
  <v-toolbar>
    <v-toolbar-title>
      <span v-if="!isLastStep" v-html="header()"></span>
      <CapTranslation
        v-if="isLastStep"
        translation="header.last_page_title"
        :params="{ financiersNum: financiersNum }"
      />
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
import CapTranslation from "./CapTranslation.vue";

export default {
  name: "LeadgeneratorHeader",
  props: {
    isLastStep: Boolean,
    question: Object,
    isScoringPage: Boolean,
  },
  data: () => ({
    prevFin: 0,
  }),
  computed: {
    financiersNum() {
      return this.$store.getters.financiersNum;
    },
    translations() {
      return this.$store.getters.translations;
    },
  },
  methods: {
    header() {
      var headerText = this.isScoringPage
        ? this.translate("scoring_page.header")
        : this.question && this.question.header && this.question.header !== ""
        ? this.question.header
        : this.$store.getters.formFields &&
          this.$store.getters.formFields.header
        ? this.$store.getters.formFields.header
        : "";

      this.prevFin = this.financiersNum != -1 ? this.financiersNum : this.prevFin;
      return headerText.replace("%financiersNum%", this.prevFin);
    },
  },
  components: {
    CapTranslation,
  },
};
</script>

<style scoped></style>
