// getters
const getters = {
    statisticsSuccess(state){
        return state.statisticsStatusSuccess;
    },
    statisticsFailed(state){
        return state.statisticsStatusFailed;
    },
    statisticsPending(state){
        return state.statisticsStatusPending;
    },
    statisticsActionStart(state) {
        return state.statisticsActionStart;
    },
    statisticsActionStep(state) {
        return state.statisticsActionStep;
    },
    statisticsActionFinish(state) {
        return state.statisticsActionFinish;
    },
    statisticsActionComplete(state) {
        return state.statisticsActionComplete;
    }
}

export default getters;