<template>
  <v-form v-model="valid" ref="form">
    <v-alert type="success" v-if="(isLastStep || isScoringPage) && emailSent && !useEntrepreneurFlow">
      <CapTranslation translation="mail.success" />
    </v-alert>
    <v-alert type="error" v-if="(isLastStep || isScoringPage) && emailError">
      <CapTranslation translation="mail.fail" />
    </v-alert>
    <v-stepper v-model="currentQuestionIndex" v-if="this.steps > 0" v-show="!isLastStep">
      <div :class="checkMobile">
        <div class="cap-row" v-if="cols == 12">
          <div class="cap-col text-right">
            <v-btn
              class="v-btn--secondary"
              v-if="(!emailSent || useEntrepreneurFlow)"
              :disabled="this.currentQuestionIndex === 1"
              icon
              :style="this.currentQuestionIndex === 1 ? {'opacity':'0.3'} : ''"
              @click="prevStep()"
            >
              <v-icon x-large :style="this.currentQuestionIndex === 1 ? {'color':'#fff !important'} : ''">navigate_before</v-icon>
            </v-btn>
          </div>
          <div class="cap-col">
            <v-btn
              class="v-btn--secondary"
              v-if="showNext"
              icon
              @click="nextStep()"
            >
              <v-icon x-large>navigate_next</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="cap-layout cap-layout-row cap-layout-wrap align-center" style="display: -webkit-box">
          <div class="cap-col-arrow-button left-arrow" v-show="cols < 12">
            <v-btn
              class="v-btn--secondary"
              v-if="(!emailSent || useEntrepreneurFlow)"
              :disabled="this.currentQuestionIndex === 1"
              icon
              :style="this.currentQuestionIndex === 1 ? {'opacity':'0.3'} : ''"
              @click="prevStep()"
            >
              <v-icon x-large :style="this.currentQuestionIndex === 1 ? {'color':'#fff !important'} : ''">navigate_before</v-icon>
            </v-btn>
          </div>
          <div class="cap-col col-main-center">
            <div class="cap-col center-stepper">
              <v-stepper-items>
                <v-stepper-content
                  v-for="(item, key, index) in this.questions"
                  :key="`${key}-content`"
                  :step="index + 1"
                >
                  <FormField
                    ref="formField"
                    :item="item"
                    :nextStep="nextStep"
                    :fieldName="key"
                    :formValue="getFormValue(key)"
                    @update-field="update"
                    @update-next="updateNext"
                    :index="index"
                    :errorOnInput="errorOnInput"
                    :hint="hint"
                  />
                </v-stepper-content>
                <v-stepper-content
                  :step="this.steps"
                  v-if="this.steps > 0 && this.showScore"
                  key="scoringPage"
                >
                  <CapScoring
                    @activate-question="activateQuestion"
                    @save-form-fields="saveFormFields"
                    :resetForm="resetForm"
                    :resetClick="resetClick"
                    :questions="questions"
                    :questionValues="formValues"
                    :nextStep="nextStep"
                    :isScoringPage="isScoringPage"
                    :useEntrepreneurFlow="useEntrepreneurFlow"
                    @update-field="update"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </div>
          </div>
          <div class="cap-col-arrow-button right-arrow" v-show="cols < 12">
            <v-btn
              class="v-btn--secondary"
              v-if="showNext"
              icon
              @click="nextStep()"
            >
              <v-icon x-large>navigate_next</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-stepper>
    <v-card flat v-if="isLastStep">
      <FinanciersModal
        v-if="showLenders"
        :formValues="getCurrentPathValues()"
        :contactForm="this.contactForm"
        :currentQuestionIndex="currentQuestionIndex"
        :steps="steps"
        :showContactForm="showContactForm"
        @update-show-contact-form="updateShowContactForm"
        :useEntrepreneurFlow="useEntrepreneurFlow"
        :useGoogleAnalytics="useGoogleAnalytics"
        :resetForm="resetForm"
        :resetClick="resetClick"
        :prevStep="prevStep"
        :nextStep="nextStep"
        :showNext="showNext"
      />
      <CapContactForm
        v-if="!showLenders && !showScore"
        :contactForm="contactForm"
        :allFormValues="getCurrentPathValues()"
      />
    </v-card>
    <ValidationDialog
      :activeQuestion="activeQuestion"
      @prevStep="prevStep"
      :isScoring="isScoringPage"
    />
    <v-dialog v-model="showContactForm" persistent :max-width="maxWidth()">
      <CapContactForm
        :contactForm="contactForm"
        :allFormValues="getCurrentPathValues()"
        @onClick="closeContactForm"
        :showCancel="true"
      />
    </v-dialog>
    <CapBrowserCheck browserPopupTitle="Title" browserPopupText="Content"/>
  </v-form>
</template>
<script>
import FinanciersModal from "./FinanciersModal.vue";
import FormField from "./FormType/FormField.vue";
import CapContactForm from "./CapContactForm.vue";
import CapTranslation from "./CapTranslation";
import CapScoring from "./CapScoring";
import ValidationDialog from "./ValidationDialog";
import CapBrowserCheck from "./CapBrowserCheck";

export default {
  name: "LeadgeneratorContent",
  props: {
    contactForm: Object,
    capStyle: Object,
    isLastStep: Boolean,
    steps: Number,
    showScore: Boolean,
    questions: Object,
    showLenders: Boolean,
    useEntrepreneurFlow: Boolean,
    useGoogleAnalytics: Boolean,
    ident: String,
  },
  data: () => ({
    valid: true,
    formValues: {},
    currentQuestionIndex: 1,
    currentPath: [],
    showContactForm: false,
    activeQuestion: {},
    isNextStep: false,
    // Custom error variable for a bug: #3193
    errorOnInput: false,
  }),
  watch: {
    steps(val) {
      if (this.currentQuestionIndex > val + 1) {
        this.currentQuestionIndex = val;
      }
      this.$emit("update", this.currentQuestionIndex);
    },
    currentPath() {
      this.activeQuestion = this.questions[this.currentPath[this.currentPath.length - 1]];
      this.$emit("activateQuestion", this.questions[this.currentPath[this.currentPath.length - 1]]);
    },
    questions() {
      this.resetForm();
    },
    financiersNum(value) {
      if (value !== 0 && value !== -1 && this.isNextStep) {
        var fieldsKey = Object.keys(this.questions);
        var key = fieldsKey[this.currentQuestionIndex - 1];
        var formValue = this.formValues[key];
        if (this.$refs.formField != undefined) {
          this.$refs.formField.forEach(function (currentTarget) {
            currentTarget.validate(key, formValue);
          });
        }

        if (formValue == "" || parseInt(formValue) == 0 || formValue == undefined) {
          return;
        }

        // Return to non-mobile view for the financiers modal
        if ((this.steps == this.currentQuestionIndex && this.$vuetify.breakpoint.name == "xs") || this.$vuetify.breakpoint.name == "sm") {
          document.getElementsByName("viewport")[0].setAttribute("content", "width=device-width,initial-scale=0.5");
        }

        var currentQuestionIndexCloned = this.currentQuestionIndex;
        this.updateNextQuestion(key, formValue);
        var nextQuestion = this.currentPath[this.currentPath.length - 1];
        if (currentQuestionIndexCloned == this.currentQuestionIndex) {
          if (!nextQuestion && this.currentQuestionIndex !== this.steps + 1) {
            this.currentQuestionIndex++;
          } else {
            var index = fieldsKey.indexOf(nextQuestion);
            this.currentQuestionIndex = index + 1;
          }
        }
        this.$emit("update", this.currentQuestionIndex);
        this.isNextStep = false;
      }
    },
  },
  computed: {
    checkMobile() {
      if (this.cols == 12) {
        return 'cap-container text-xs-center'
      } else {
        return 'd-flex justify-content-between';
      }
    },
    // See if the email failed to send
    emailError() {
      return this.$store.getters.saveFormFieldsStatusFail;
    },
    // See if the email has successfully been sent
    emailSent() {
      return this.$store.getters.saveFormFieldsStatusSuccess;
    },
    // Check if the score is orange or green
    scoreSufficient() {
      if (this.score) {
        return this.score.trafficLight <= 1 ? false : true;
      }
      return false;
    },
    // Return current score
    score() {
      return this.$store.getters.score ? this.$store.getters.score : false;
    },
    // This detirments if we show or hide the "next" arrow
    showNext() {
      // If we're on the scoring page and the score is insufficient,
      // or if we're on the scoring page and the contact email is sent
      // : don't show the next button
      if ((this.isScoringPage && !this.scoreSufficient) || (this.isScoringPage && (this.emailSent && !this.useEntrepreneurFlow))) {
        return false;
      }
      return true;
    },
    // On small resolutions like mobile, we use the full bootstrap width
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        default:
          return 10;
      }
    },
    // Check if we are on the scoring page
    isScoringPage() {
      return this.steps > 1 && this.showScore && this.steps === this.currentQuestionIndex;
    },
    // Get the current available financiers total
    financiersNum() {
      return this.$store.getters.financiersNum;
    },
    // Get the current session ID
    session() {
      return this.$store.getters.session;
    },
    // Get all currently available financiers
    financiers() {
      return this.$store.getters.financierList;
    },
  },
  methods: {
    nextStep() {
      // If someone clicks the next button and we're on the score page..
      if (this.steps > 0 && (this.isLastStep || this.isScoringPage)) {
        // On the score page "ga verder" click...
        this.showContactFormOrRedirect();
      // .. if someone clicks the "next" button and we're not on the last page
      } else {
        // Whatever happens next, save the current answers (with isFinal: false)
        this.saveFormFields(false);
        if (this.showScore && this.steps == this.currentQuestionIndex) {
          this.currentQuestionIndex++;
          this.$emit("update", this.currentQuestionIndex);
        } else {
          // This fixes #3193
          // This makes the text red if there's no answer selected and prevents the dialog from showing up, but only for the first question
          if (this.currentQuestionIndex === 1) {
            if (!this.AnswerIsSelected()) {
              // No answer has been selected, but the next arrow has been clicked, so make the text red
              this.errorOnInput = true;
            } else {
              // An answer has been clicked, so we can set the error var to false, and continue with the app
              // This will either go to the next question, or display a validation dialog with a message
              this.errorOnInput = false;
              this.nextStepTrue();
            }
          } else {
            // For all questions after the first, we ignore the new code above
            this.nextStepTrue();
          }
        }
      }
    },
    // If the "nextStep()" succeeds, we continue here to go to the next question
    nextStepTrue() {
      this.errorOnInput = false;
      this.isNextStep = true;
      this.saveAnswerToGA();
      // Send the "next step" record with the step number
      this.$store.dispatch("addStatistic", {
        action: this.$store.getters.statisticsActionStep,
        arg: this.getCurrentQuestionID()
      });

      // Get new financier list
      this.$store.dispatch("setFinancierList", {
        fields: this.getCurrentPathValues()
      });
    },
    // Save the question ID, question title and answer to Google Analytics
    saveAnswerToGA() {
      let question = this.getCurrentPathValues();
      let selectedAnswer = question['question'+this.getCurrentQuestionID()];
      if (typeof(selectedAnswer) === 'undefined') return false;
      if (selectedAnswer == 0 || selectedAnswer == false) return false;
      if (this.useGoogleAnalytics) {
        if (Array.isArray(selectedAnswer)) {
          // Array answers are currently not supported - we can add it if a customer wants it
          // This is only used for the postal code component for ING
          selectedAnswer = "-";
        }
        this.$analytics.trackEvent('Answer', 'ID '+this.getCurrentQuestionID(), this.getCurrentQuestionTitle(), selectedAnswer);
      }
    },
    // Check if an answer is selected or entered
    AnswerIsSelected() {
      let question = this.getCurrentPathValues();
      let selectedAnswer = question[Object.keys(question)[0]];
      if (typeof(selectedAnswer) === 'undefined') return false;
      if (selectedAnswer == 0 || selectedAnswer == false) return false;
      return true;
    },
    // Check if we want to use the entrepeneur flow or just open the contact form
    showContactFormOrRedirect() {
        if (this.useEntrepreneurFlow) {
            window.open(process.env.VUE_APP_CAPSEARCH_URL + '/create-account/'+this.ident+'/'+this.session, "_self");
        } else {
          if (!this.emailSent)
            this.showContactForm = true;
        }
    },
    // Gets the current question ID for the statistics in the back-end
    getCurrentQuestionID() {
      let question = this.getCurrentPathValues();
      let questionNames = Object.keys(question);
      let currentQuestion = questionNames.reverse()[0];

      return this.$store.getters.formFields.properties[currentQuestion].id;
    },
    // Gets the title of the current question
    // e.g. "Hoeveel financiering is er nodig?"
    getCurrentQuestionTitle() {
      let question = this.getCurrentPathValues();
      let questionNames = Object.keys(question);
      let currentQuestion = questionNames.reverse()[0];

      return this.$store.getters.formFields.properties[currentQuestion].title;
    },
    // Called from the financiers modal when "ga verder" is clicked
    updateShowContactForm() {
      this.showContactFormOrRedirect();
    },
    // GO back a question
    prevStep() {
      this.isNextStep = false;
      //check if it's the last step and remove the mobile scale
      if ((this.isLastStep && this.$vuetify.breakpoint.name == "xs") || this.$vuetify.breakpoint.name == "sm") {
        document
          .getElementsByName("viewport")[0]
          .setAttribute("content", "width=device-width,initial-scale=1");
      }

      //if the question is not the first one, and it's not the financiers when the score is shown
      if (this.currentQuestionIndex !== 1 && (this.steps + 1 !== this.currentQuestionIndex || !this.showScore)) {

        this.currentPath.pop();
        var prevQuestion = this.currentPath[this.currentPath.length - 1];
        var index = Object.keys(this.questions).indexOf(prevQuestion);
        this.currentQuestionIndex = index + 1;

        this.$store.dispatch("addStatistic", {
          action: this.$store.getters.statisticsActionStep,
          arg: this.getCurrentQuestionID()
        });

        this.$store.dispatch("setFinancierList", {
          fields: this.getCurrentPathValues(),
        });
      } else if (this.showScore && this.steps + 1 === this.currentQuestionIndex) {
        this.currentQuestionIndex--;
      }

      this.$emit("update", this.currentQuestionIndex);
    },
    // Called from scoring page when entrepreneur flow is enabled
    // "score" is a score array, see CapScoring.vue
    saveFormFields(isFinal) {
      this.$store.dispatch("saveFormFields", {
        answers: this.getCurrentPathValues(),
        financiers: this.financiers,
        contactForm: [], // No form used so no data here
        isFinal: isFinal
      });
    },
    update(key, value) {
      this.formValues[key] = value;
    },
    updateNextQuestion(key, value) {
      var found = false;
      //if it is a previous step, then first, remove the question
      if (this.currentPath[this.currentPath.length - 1] != key) {
        this.currentPath.pop();
      }

      //if the question has answers or bandwidths
      if (this.isset(this.questions, key) && this.questions[key].answers) {
        for (var i = 0; i < this.questions[key].answers.length && !found; i++) {
          var orderValue = this.questions[key].answers[i];
          //if the answer is a bandwidth, then contains toLimit (unless is the last one)
          //otherwise ignore the toLimit when is null
          var toLimit = orderValue.toLimit == null || value <= orderValue.toLimit;
          //if it's a bandwidth then, the value has to be bigger or equal to fromLimit
          var fromLimitCurrency = this.questions[key] === "currency" && orderValue.fromLimit <= value;
          //if it's a choice, only contains fromLimit and the value should be equal
          var fromLimitChoice = this.questions[key] !== "currency" && orderValue.fromLimit == value;
          if (toLimit && (fromLimitCurrency || fromLimitChoice)) {
            //if the next question is the last page, then go to the last step
            if (orderValue.lastPage) {
              found = true;
              //if the scoring page is shown, then it's included inside the steps,
              //otherwise goes to financier table and it's not inclued inside the steps, that's why +1
              this.currentQuestionIndex = this.showScore ? this.steps : this.steps + 1;
              //dummy page name to identify that is in the last page
              this.currentPath.push("scoring");
            } else {
              //if the answer has a next question, then goes directly to that one.
              if (orderValue.nextQuestion != null) {
                found = true;
                this.currentPath.push(orderValue.nextQuestion);
              }
            }
          }
        }
      }

      if (!found) {
        //if the answer doesn't contain next question, the goes to next question based on the "sortOder"
        var nextQuestion = this.findNextQuestion();
        this.currentPath.push(nextQuestion);
      }
    },
    // Get the index of the next question
    findNextQuestion() {
      var keys = Object.keys(this.questions);
      if (keys.length > this.currentQuestionIndex) {
        return keys[this.currentQuestionIndex];
      }

      return false;
    },
    // Regenerate the current session ID
    resetClick() {
      const that = this;
      // Call to the API to regenerate the session ID
      this.$store.dispatch('resetSessionID', {}).then(() => {
        // After the call, reset all forms
        that.resetForm();
      });
      if (this.useGoogleAnalytics) {
        this.$analytics.trackEvent('Click', 'Reset', 'Leadgenerator reset');
      }
    },
    // This resets all forms in the LG and is used on every input component
    resetForm() {
      // Empty the current question
      this.currentPath = [];
      // Put the first question back in the currentPath variable
      var keys = Object.keys(this.questions);
      if (keys.length > 0) {
        this.currentPath.push(keys[0]);
      }

      // Foreach form do a reset
      if (this.$refs.formField != undefined) {
        this.$refs.formField.forEach(function (currentTarget) {
          currentTarget.resetForm();
        });
      }

      if (this.currentQuestionIndex !== 1) {
        // Reset financier list
        this.$store.dispatch("setFinancierList", {
          fields: {}
        });
        // Send another "start" record for the statistics
        this.$store.dispatch("addStatistic", {
          action: this.$store.getters.statisticsActionStart
        });
      }

      // Set the step to 1 and update it so the parents has the same info
      this.currentQuestionIndex = 1;
      this.$emit("update", this.currentQuestionIndex);

      // Set this to false, incase someone has submitted the form, so the "thank you for contacting us" message goes away
      this.$store.commit("SAVE_FORM_FIELDS_STATUS_SUCCESS", false);
    },
    // This gets the entered answer for 1 question
    getFormValue(key) {
      return this.isset(this.formValues, key) &&
        this.formValues[key] !== undefined
        ? this.formValues[key].toString()
        : "";
    },
    // This gets all answers to all question in an object
    // eg {question37: 1000, question12: Nee, ....}
    getCurrentPathValues() {
      var result = {};
      var $this = this;
      this.currentPath.forEach((element) => {
        if ($this.isset($this.formValues, element)) {
          result[element] = $this.formValues[element];
        } else {
          result[element] = "";
        }
      });

      return result;
    },
    updateNext(key, value) {
      this.update(key, value);
      this.nextStep();
    },
    // Set the current active question
    activateQuestion(question) {
      this.activeQuestion = question;
    },
    closeContactForm: function () {
      this.showContactForm = false;
    },
  },
  components: {
    FormField,
    FinanciersModal,
    CapContactForm,
    CapTranslation,
    CapScoring,
    ValidationDialog,
    CapBrowserCheck
  },
  mounted() {
    // Clear everything when the LG is first loaded
    this.resetForm();
    if (this.useGoogleAnalytics) {
      this.$analytics.trackView('Leadgenerator load');
    }
  },
};
</script>
