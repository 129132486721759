<template>
  <v-text-field
    :rules="rules"
    v-model="fieldValue"
    solo
    clearable
    :placeholder=label
    :error="errors"
  ></v-text-field>
</template>
<script>
export default {
  name: "CapContactTextInput",
  props: {
    nextStep: Function,
    step: Number,
    fieldName: String,
    label: String,
    errors: Boolean,
    rules: Array,
    contactPage: Boolean,
  },
  data: () => ({
    fieldValue: "",
  }),
  watch: {
    fieldValue: function (value) {
      this.$emit("update-text", this.fieldName, value);
    },
  },
  methods: {
    resetForm() {
      this.fieldValue = "";
      this.$emit("update-text", this.fieldName, this.fieldValue);
    },
  },
};
</script>
