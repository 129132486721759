<template>
  <v-app
    :style="{
      width: width,
      minWidth: minWidth,
      margin: 'auto',
    }"
  >
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    <v-card v-if="loaded">
      <LeadgeneratorHeader
        :isLastStep="isLastStep"
        :question="activeQuestion"
        :isScoringPage="isScoringPage"
      />
      <ProgressBar
        v-if="step <= questionNum"
        :totalSteps="questionNum"
        :activeStep="step"
        :resetForm="resetForm"
        :resetClick="resetClick"
      />
      <LeadgeneratorContent
        ref="content"
        :formFields="fields"
        @update="update"
        @activateQuestion="activateQuestion"
        :capStyle="style"
        :steps="steps"
        :showScore="showScore"
        :questions="fields ? fields.properties : {}"
        :contactForm="fields ? fields.contactForm : {}"
        :showLenders="fields ? fields.showLenders : false"
        :useEntrepreneurFlow="fields ? fields.useEntrepreneurFlow : false"
        :useGoogleAnalytics="useGoogleAnalytics ? true : false"
        :isLastStep="isLastStep"
        :ident="ident"
      />
      <LeadgeneratorFooter
        :isLastStep="isLastStep"
        :showCarousel="fields ? fields.showCarousel : false"
        :showFooter="fields ? fields.showFooter : false"
      />
    </v-card>
  </v-app>
</template>
<script>
import LeadgeneratorFooter from "./components/LeadgeneratorFooter.vue";
import LeadgeneratorHeader from "./components/LeadgeneratorHeader.vue";
import LeadgeneratorContent from "./components/LeadgeneratorContent.vue";
import ProgressBar from "./components/ProgressBar.vue";
const version = require("../package.json").version;

export default {
  name: "App",
  components: {
    LeadgeneratorFooter,
    LeadgeneratorHeader,
    LeadgeneratorContent,
    ProgressBar,
  },
  data: () => ({
    step: 0,
    activeQuestion: null,
  }),
  computed: {
    version() {
      return version;
    },
    fields() {
      return this.$store.getters.formFields;
    },
    useEntrepreneurFlow() {
      return this.$store.getters.useEntrepreneurFlow;
    },
    useGoogleAnalytics() {
      return this.$store.getters.formFields.trackingId ? true: false;
    },
    style() {
      return this.fields ? this.fields.style : null;
    },
    isLastStep() {
      return this.steps < this.step;
    },
    minWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        default:
          return this.width;
      }
    },
    steps() {
      var steps = this.fields ? Object.keys(this.fields.properties).length : 0;
      return this.showScore && steps > 0 ? steps + 1 : steps;
    },
    showScore() {
      return this.fields ? this.fields.showScore : false;
    },
    questionNum() {
      return this.showScore ? this.steps - 1 : this.steps;
    },
    isScoringPage() {
      if (this.showScore && this.steps == this.step) {
        if (this.useGoogleAnalytics) {
          this.$analytics.trackEvent('Answer', 'Finished', 'Finished all questions');
        }
        return true;
      } else {
        return false;
      }
    },
    loaded() {
      return this.$store.getters.transLoaded && this.$store.getters.finLoaded;
    },
  },
  methods: {
    update: function (currentStep) {
      this.step = currentStep;
      if (this.isLastStep || this.isScoringPage) {
        this.$store.dispatch("addStatistic", {
          action: this.$store.getters.statisticsActionComplete
        });
      }
    },
    activateQuestion(data) {
      this.activeQuestion = data;
    },
    resetClick() {
      const that = this;
      if (this.useGoogleAnalytics) {
        this.$analytics.trackEvent('Click', 'Reset', 'Leadgenerator reset');
      }
      // Call to the API to regenerate the session ID
      this.$store.dispatch('resetSessionID', {}).then(() => {
        // After the call, reset all forms
        that.resetForm();
      });
    },
    // This function resets all forms
    // It runs when the user first loads the LG, and when the user clicks "start opnieuw"
    resetForm() {
      this.$refs.content.resetForm();
    },
    addFont: function () {
      if (this.fields.fontRegular && this.fields.fontRegular !== "") {
        var fontStyle = document.createElement("style");
        var fonts =
          "@font-face { font-family: OrganizationFont; src: url(" +
          this.fields.fontRegular +
          ') format("woff"); font-weight: normal; font-style: normal;}' +
          '.v-application {font-family: "OrganizationFont" !important;}';
        fontStyle.type = "text/css";
        fontStyle.innerText = fonts;
        document.head.appendChild(fontStyle);
      }
    },
    setFinLoaded() {
      this.finLoaded = true;
    },
  },
  watch: {
    style(value) {
      if (value) {
        document.documentElement.style.setProperty(
          "--btn-primary-color",
          value.mainBtnColor
        );
        document.documentElement.style.setProperty(
          "--btn-primary-text",
          value.mainBtnTextColor
        );
        document.documentElement.style.setProperty(
          "--btn-primary-border",
          value.mainBtnBorderColor
        );
        document.documentElement.style.setProperty(
          "--btn-primary-hover-color",
          value.mainBtnHoverBackgroundColor
        );
        document.documentElement.style.setProperty(
          "--btn-primary-hover-text",
          value.mainBtnHoverTextColor
        );
        document.documentElement.style.setProperty(
          "--btn-primary-hover-border",
          value.mainBtnHoverBorderColor
        );
        document.documentElement.style.setProperty(
          "--btn-border-radius",
          value.btnBorderRadius
        );
        document.documentElement.style.setProperty(
          "--btn-border-width",
          value.btnBorderWidth
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-color",
          value.secBtnColor
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-text",
          value.secBtnTextColor
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-border",
          value.secBtnBorderColor
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-hover-color",
          value.secBtnHoverBackgroundColor
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-hover-text",
          value.secBtnHoverTextColor
        );
        document.documentElement.style.setProperty(
          "--btn-secondary-hover-border",
          value.secBtnHoverBorderColor
        );
        document.documentElement.style.setProperty(
          "--main-text-color",
          value.mainTextColor
        );
        document.documentElement.style.setProperty(
          "--main-color",
          value.mainBackColor
        );
        document.documentElement.style.setProperty(
          "--footer-text-color",
          value.footerTextColor
        );
        document.documentElement.style.setProperty(
          "--footer-color",
          value.footerColor
        );
        document.documentElement.style.setProperty(
          "--footer-top-border-color",
          value.footerBorderColor
        );
        document.documentElement.style.setProperty(
          "--background",
          value.backgroundColor
        );
        document.documentElement.style.setProperty(
          "--table-header-color",
          value.tableHeaderColor
        );
        document.documentElement.style.setProperty(
          "--table-header-text-color",
          value.tableHeaderTextColor
        );
        document.documentElement.style.setProperty(
          "--font-regular",
          value.fontRegular
        );
        document.documentElement.style.setProperty(
          "--font-bold",
          value.fontBold
        );
        document.documentElement.style.setProperty(
          "--font-bold-italic",
          value.fontBoldItalic
        );
        document.documentElement.style.setProperty(
          "--font-italic",
          value.fontItalic
        );

        this.$vuetify.theme.themes.light.primary = value.mainBackColor;
        this.addFont();
      }
    },
    fields() {
      this.$store.dispatch("addStatistic", {
        action: this.$store.getters.statisticsActionStart,
      });
      this.$store.dispatch("setTranslations", {});
      this.$store.dispatch("setAllFinOrgs", {});
      this.$store.dispatch("setFinancierList", {
        fields: {},
      });
      if (this.useGoogleAnalytics) {
        this.initGoogleAnalytics(this.$store.getters.formFields.trackingId, this.version);
      }
    },
  },
  props: {
    width: String,
    ident: String,
  },
  mounted() {
    const that = this;
    this.$store.dispatch("setIdent", this.ident);
    // Make sure we generate a new session ID on every page load
    this.$store.dispatch("resetSessionID", {}).then(() => {
        that.$store.dispatch("setFormFields", {});
    });
  },
};
</script>
