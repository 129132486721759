import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const financiers = {
    namespaced: false,
    // initial state
    state: {
        financierList: {},
        financiersNum: -1,
        allFinOrgs: {},
        maxFinanciers: 0,
        financierListStatusPending: null,
        financierListStatusSuccess: null,
        financierListStatusFail: null,
        allFinOrgsStatusPending: null,
        allFinOrgsStatusSuccess: null,
        allFinOrgsStatusFail: null,
    },
    getters,
    mutations,
    actions
};

export default financiers;