<template>
<div :id="id" class="cap-icon">
  <v-dialog v-model="dialog" scrollable max-width="400px" max-height="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon :color="color">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card @click="closeDialog" :ripple="false">
      <!-- empty title to center the v-card-text -->
      <v-card-title></v-card-title>
      <v-card-text v-html="tooltipText"></v-card-text>
    </v-card>
  </v-dialog> 
</div>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
  }),
  name: "CapIcon",
  props: {
    tooltipText: String,
    icon: String,
    color: String,
  },
  computed: {
    id() {
      var count = document.getElementsByClassName("cap-icon").length + 1;
      return "capIcon" + count;
    },
  },
  methods: {
    closeDialog: function () {
        this.dialog = false
    },
  }
};
</script>