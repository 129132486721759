// actions
import axios from "axios";
const actions = {
    setFinancierList(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;

        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 POST requests to /api/leadgenerator/v1/financiers'+ident+ ' ('+session+')'});
            return;
        }

        context.commit("SET_FINANCIER_LIST_STATUS_PENDING", true);
        context.commit("SET_FINANCIER_NUM", -1);
        axios
            .post(
                process.env.VUE_APP_CAPSEARCH_URL + "/api/leadgenerator/v1/financiers" + ident,
                {
                    fields: data.fields,
                    step: data.step,
                    session: context.getters.session
                }
            )
            //success
            .then(({ data }) => {
                context.commit("SET_FINANCIER_LIST", data);
                var finNum = Object.keys(data).length;
                context.commit("SET_FINANCIER_NUM", finNum);
                context.commit("SET_FINANCIER_LIST_STATUS_SUCCESS", true);
                context.commit("SET_FINANCIER_LIST_STATUS_FAIL", false);
            })
            //fail
            .catch(() => {
                // When the request fails, retry
                this.dispatch("setFinancierList", {
                    fields: data.fields,
                    step: data.step,
                    retry: data.retry ? data.retry + 1 : 1
                });
                // This was the old code (before the request retry) which is not needed right now but let's keep it incase we need it
                // context.commit("SET_FINANCIER_LIST", {});
                // context.commit("SET_FINANCIER_NUM", -1);
                // context.commit("SET_FINANCIER_LIST_STATUS_SUCCESS", false);
                // context.commit("SET_FINANCIER_LIST_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SET_FINANCIER_LIST_STATUS_PENDING", false);
            });
    },
    setAllFinOrgs(context, data) {
        var ident = context.getters.ident ? "/" + context.getters.ident : "";
        var session = context.getters.session;

        // If after 3 times the request still fails, stop trying!
        if (data.retry && data.retry >= 3) {
            window.logToCapsearch({'message': 'Failed 3 GET requests to /api/leadgenerator/v1/finorgs'+ident+ ' ('+session+')'});
            return;
        }

        context.commit("SET_ALL_FIN_ORGS_STATUS_PENDING", true);
        axios
            .get(process.env.VUE_APP_CAPSEARCH_URL + "/api/leadgenerator/v1/finorgs"+ident)
            //success
            .then(({ data }) => {
                context.commit("SET_ALL_FIN_ORGS", data);
                context.commit("SET_ALL_FIN_ORGS_STATUS_SUCCESS", true);
                context.commit("SET_ALL_FIN_ORGS_STATUS_FAIL", false);
            })
            //fail
            .catch(() => {
                this.dispatch("setAllFinOrgs", {
                    retry: data.retry ? data.retry + 1 : 1
                });
                // context.commit("SET_ALL_FIN_ORGS", {});
                // context.commit("SET_ALL_FIN_ORGS_STATUS_SUCCESS", false);
                // context.commit("SET_ALL_FIN_ORGS_STATUS_FAIL", true);
            })
            //finally
            .finally(() => {
                context.commit("SET_ALL_FIN_ORGS_STATUS_PENDING", false);
            });
    },
}

export default actions;