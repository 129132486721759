// getters
const getters = {
    formFields(state) {
        return state.formFields;
    },
    saveFormFieldsStatusSuccess(state) {
        return state.saveFormFieldsStatusSuccess;
    },
    saveFormFieldsStatusFail(state) {
        return state.saveFormFieldsStatusFail;
    },
}

export default getters;