import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const statistics = {
    namespaced: false,
    // initial state
    state: {
        statisticsStatusPending: null,
        statisticsStatusSuccess: null,
        statisticsStatusFail: null,
        // Start action, only add record when loading the LG
        statisticsActionStart: 1,
        // Step action, only add record when answering a question
        statisticsActionStep: 2,
        // Complete action, only add record when the contact form is sent
        statisticsActionFinish: 3,
        // Finish action, only add record when all questions score page is visible
        statisticsActionComplete: 4,
    },
    getters,
    mutations,
    actions
};

export default statistics;