// actions
const actions = {
    setIdent(context, data) {
        if (data != undefined) {
            context.commit("SET_IDENT", data);
        }
    },
    setSession(context, data) {
        if (data != undefined) {
            context.commit("SET_SESSION", data);
        }
    },
}
export default actions;