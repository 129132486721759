import Vue from "vue";
import Vuex from "vuex";
import fields from "./modules/fields/index";
import financiers from "./modules/financiers/index";
import translations from "./modules/translations/index";
import authentication from "./modules/authentication/index";
import statistics from "./modules/statistics/index";
import scoring from "./modules/scoring/index";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
const store = new Vuex.Store({
  modules: {
    fields: fields,
    financiers: financiers,
    translations: translations,
    authentication: authentication,
    statistics: statistics,
    scoring: scoring
  },
  strict: debug,
});
export default store;
