// mutations
const mutations = {
    SET_SCORE(state, payload) {
        state.score = payload;
    },
    GET_SCORE_STATUS_PENDING(state, payload) {
        state.scoreStatusPending = payload;
    },
    GET_SCORE_STATUS_SUCCESS(state, payload) {
        state.scoreStatusSuccess = payload;
    },
    GET_SCORE_STATUS_FAIL(state, payload) {
        state.scoreStatusFail = payload;
    }
}

export default mutations;