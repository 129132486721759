// mutations
const mutations = {
    SET_FORM_FIELDS(state, payload) {
        state.formFields = payload;
    },
    SET_FORM_FIELDS_STATUS_PENDING(state, payload) {
        state.formFieldsStatusPending = payload;
    },
    SET_FORM_FIELDS_STATUS_SUCCESS(state, payload) {
        state.formFieldsStatusSuccess = payload;
    },
    SET_FORM_FIELDS_STATUS_FAIL(state, payload) {
        state.formFieldsStatusFail = payload;
    },
    SAVE_FORM_FIELDS_STATUS_FAIL(state, payload) {
        state.saveFormFieldsStatusFail = payload;
    },
    SAVE_FORM_FIELDS_STATUS_PENDING(state, payload) {
        state.saveFormFieldsStatusPending = payload;
    },
    SAVE_FORM_FIELDS_STATUS_SUCCESS(state, payload) {
        state.saveFormFieldsStatusSuccess = payload;
    },
}

export default mutations;