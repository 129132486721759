<template>
  <vue-speedometer
    :height="150"
    :value="2 * value - 1"
    needleColor="black"
    :minValue="0"
    :maxValue="6"
    :segments="3"
    :ringWidth="100"
    :needleTransitionDuration="2500"
    :segmentColors="['#ff0000', '#f8fc03', '#349651']"
    :customSegmentStops="[0, 2, 4, 6]"
    :customSegmentLabels="[
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ]"
  />
</template>
<script>
import VueSpeedometer from "vue-speedometer";
export default {
  name: "CapSpeedometer",
  props: {
    value: Number,
  },
  components: { VueSpeedometer },
};
</script>
