// mutations
const mutations = {
    SET_FINANCIER_LIST(state, payload) {
        state.financierList = payload;
    },
    SET_FINANCIER_NUM(state, payload){
        state.financiersNum = payload;
    },
    SET_FINANCIER_LIST_STATUS_PENDING(state, payload) {
        state.financierListStatusPending = payload;
    },
    SET_FINANCIER_LIST_STATUS_SUCCESS(state, payload) {
        state.financierListStatusSuccess = payload;
    },
    SET_FINANCIER_LIST_STATUS_FAIL(state, payload) {
        state.financierListStatusFail = payload;
    },
    SET_ALL_FIN_ORGS(state, payload) {
        state.allFinOrgs = payload;
    },
    SET_ALL_FIN_ORGS_STATUS_PENDING(state, payload) {
        state.allFinOrgsStatusPending = payload;
    },
    SET_ALL_FIN_ORGS_STATUS_SUCCESS(state, payload) {
        state.allFinOrgsStatusSuccess = payload;
    },
    SET_ALL_FIN_ORGS_STATUS_FAIL(state, payload) {
        state.allFinOrgsStatusFail = payload;
    },
}

export default mutations;