<template>
  <div>
    <v-select
      style="margin-left:15px;"
      v-model="fieldValue"
      @change="update()"
      :items="items"
      label="Selecteer"
      outlined
      :error="errors"
      attach
    ></v-select>
    <div class="dropdown-spacer"></div>
  </div>
</template>
<script>
export default {
  name: "CapSelectChoice",
  props: {
    choices: Object,
    step: Number,
    fieldName: String,
    errors: Boolean,
    rules: Array
  },
  data: () => ({
    fieldValue: 0,
  }),
  methods: {
    update() {
      this.$emit("update-selected", this.fieldName, this.fieldValue);
    },
    resetForm() {
      this.fieldValue = 0;
      this.$emit("update", this.fieldName, this.fieldValue);
    },
  },
  computed: {
    items: function() {
      var itemsArray = [];
      for (const [key, value] of Object.entries(this.choices)) {
        itemsArray.push({ text: value, value: key });
      }
      return itemsArray;
    },
  },
};
</script>

<style scoped>
.dropdown-spacer {
  height:125px;
}
</style>