import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const translations = {
    namespaced: false,
    // initial state
    state: {
        translations: null,
        translationsStatusPending: null,
        translationsStatusSuccess: null,
        translationsStatusFail: null,
    },
    getters,
    mutations,
    actions
};

export default translations;