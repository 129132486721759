// getters
const getters = {
    ident(state) {
        return state.ident;
    },
    session(state) {
        return state.session;
    },
}

export default getters;