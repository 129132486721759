<template>
  <div class="text-input">
    <v-text-field
      :error="errors"
      v-model="initialBalanceFormatted"
      outlined
      :label="label"
      prepend-inner-icon="euro_symbol"
      @keyup.enter.native="nextStep()"
      :hint="hint"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "CapCurrencyInput",
  props: {
    nextStep: Function,
    step: Number,
    label: String,
    fieldName: String,
    value: Number,
    hint: String,
    errors: Boolean,
    rules: Array
  },
  data: () => ({
    fieldValue: 0,
  }),
  computed: {
    initialBalanceFormatted: {
      get: function() {
        return this.formatAsCurrency(parseFloat(this.fieldValue), 0);
      },
      set: function(newValue) {
        if (newValue) {
          this.fieldValue = isNaN(parseFloat(newValue.replace(/[^0-9,]/g, "")))
            ? 0
            : Number(newValue.replace(/[^0-9,]/g, ""));
        } else {
          this.fieldValue = 0;
        }

        this.$emit("update-number", this.fieldName, this.fieldValue);
      },
    }
  },
  methods: {
    formatAsCurrency: function(value, dec) {
      dec = dec || 0;
      if (value === null || isNaN(parseFloat(value))) {
        return 0;
      }

      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    },
    resetForm() {
      this.fieldValue = 0;
      this.$emit("update-number", this.fieldName, this.fieldValue);
    },
  },
  watch: {
    value: function(value){
        this.fieldValue = value;
    }
  },
  mounted(){
      this.fieldValue = this.value;
  }
};
</script>
