var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-speedometer',{attrs:{"height":150,"value":2 * _vm.value - 1,"needleColor":"black","minValue":0,"maxValue":6,"segments":3,"ringWidth":100,"needleTransitionDuration":2500,"segmentColors":['#ff0000', '#f8fc03', '#349651'],"customSegmentStops":[0, 2, 4, 6],"customSegmentLabels":[
    {
      text: '',
    },
    {
      text: '',
    },
    {
      text: '',
    },
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }