<template>
  <div class="text-input">
    <v-text-field
      :rules="rules"
      v-model="fieldValue"
      outlined
      :label="label"
      @keyup.enter.native="nextStep()"
      :error="errors"
      :hint="hint"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "CapTextInput",
  props: {
    nextStep: Function,
    step: Number,
    fieldName: String,
    label: String,
    errors: Boolean,
    hint: String,
    rules: Array,
  },
  data: () => ({
    fieldValue: "",
  }),
  watch: {
    fieldValue: function (value) {
      this.$emit("update-text", this.fieldName, value);
    },
  },
  methods: {
    resetForm() {
      this.fieldValue = "";
      this.$emit("update-text", this.fieldName, this.fieldValue);
    },
  },
};
</script>